import "../styles/admin.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { APP_BASE_URL, getToken } from "../utils/utility.js";

export default function Admin() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [allSummary, setAllSummary] = useState({});
	const [todaySummary, setTodaySummary] = useState({});
	//
	useEffect(() => {
		getData();
	}, []);
	//
	const getData = async function () {
		try {
			const allResult = await adminController.getSummary(false);
			if (allResult.isSuccess === false) throw allResult.message;
			setAllSummary(allResult.value);
			//
			const todayResult = await adminController.getSummary(true);
			if (todayResult.isSuccess === false) throw todayResult.message;
			setTodaySummary(todayResult.value);
		} catch (error) {
			alert(error.toString());
		}
	};
	//
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="admin">
				<div className="summary">
					<h2>امروز</h2>
					<ul>
						<li>
							<strong>{todaySummary.totalUsers}</strong>
							<span>کاربران</span>
						</li>
						<li>
							<strong>{todaySummary.totalRequests}</strong>
							<span>درخواست ها</span>
						</li>
					</ul>
					<h2>همه</h2>
					<ul>
						<li>
							<strong>{allSummary.totalUsers}</strong>
							<span>کاربران</span>
						</li>
						<li>
							<strong>{allSummary.totalRequests}</strong>
							<span>درخواست ها</span>
						</li>
					</ul>

					<div className="buttons">
						<a href="/#/users">مشاهده کاربران</a>
						<a href="/#/edit-stores">تعریف فروشگاه ها</a>
						<a href="/#/edit-levels">سطوح مشتریان</a> 
						<a href="/#/edit-residences">اقامتگاه ها</a> 
					</div>
				</div>
			</article>
		</>
	);
}
