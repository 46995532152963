import "../styles/edit-residenceLimits.css";
import "../styles/form.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { API_BASE_URL, APP_BASE_URL, getToken } from "../utils/utility.js";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { Editor } from "primereact/editor";

export default function EditResidenceLimits() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	//
	const [residenceLimitId, setResidenceLimitId] = useState("");
	const [residenceId, setResidenceId] = useState("");
	const [maxTotal, setMaxTotal] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [createdOn, setCreatedOn] = useState("");
	const [residenceLimitStates, setResidenceLimitStates] = useState([]);
	//
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	const [residenceLimits, setResidenceLimits] = useState([]);
	//
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [isUploading, setIsUploading] = useState(false);
	//> useEffect
	useEffect(() => {
		getData();
	}, [residenceId,residenceLimitStates]);
	//> updateMaxTotal
	const updateMaxTotal = function (index, value) {
		let newResidenceLimitStates = residenceLimitStates.map((item) => {
			return item.index === index ? { ...item, maxTotal: value } : item;
		});
		alert(JSON.stringify(newResidenceLimitStates));
		setResidenceLimitStates(newResidenceLimitStates);
	};
	//> onPageChange
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				setResidenceId(urlParams.get("residenceId"));
			}

			const residenceLimitsResult = await adminController.getResidenceLimits(
				residenceId,
				Math.floor(first / 12) + 1,
				12
			);
			if (residenceLimitsResult.isSuccess === false)
				throw residenceLimitsResult.message;

			residenceLimitsResult.value.map((item) => {
				item.startDate = new Date(item.startDate);
				item.endDate = new Date(item.endDate);
			});

			let lastRowNumber = first - 1;
			residenceLimitsResult.value.map((item) => (item.index = ++lastRowNumber));

			setResidenceLimits(residenceLimitsResult.value);
			setTotalRecords(residenceLimitsResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> resetResidenceLimit
	const resetResidenceLimit = async function () {
		try {
			setPageType("edit");

			setResidenceLimitId("00000000-0000-0000-0000-000000000000");
			setMaxTotal("");
			setStartDate("");
			setEndDate("");
			setCreatedOn(null);
			//
			await getResidenceLimitStates();
		} catch (error) {
			alert(error.toString());
		}
	};

	//> editResidenceLimit
	const editResidenceLimit = async function (objResidenceLimit) {
		try {
			setPageType("edit");

			setResidenceLimitId(objResidenceLimit.residenceLimitId);
			setMaxTotal(objResidenceLimit.maxTotal);
			setStartDate(objResidenceLimit.startDate);
			setEndDate(objResidenceLimit.endDate);
			setCreatedOn(objResidenceLimit.createdOn);
			//
			await getResidenceLimitStates();
		} catch (error) {
			alert(error.toString());
		}
	};
	//>
	const getResidenceLimitStates = async function () {
		const residenceLimitStatesResult =
			await adminController.getResidenceLimitStates(residenceLimitId);
		if (residenceLimitStatesResult.isSuccess === false)
			throw residenceLimitStatesResult.message;

		let lastRowNumber = 0;
		residenceLimitStatesResult.value.map(
			(item) => (item.index = ++lastRowNumber)
		);

		setResidenceLimitStates(residenceLimitStatesResult.value);
	};

	//> updateResidenceLimit
	const updateResidenceLimit = async function () {
		try {
			let errors = [];

			if (!maxTotal <= 0) errors.push("حداکثر تعداد را وارد نمایید");
			if (!startDate) errors.push("تاریخ شروع را وارد نمایید");
			if (!endDate) errors.push("تاریخ پایان را وارد نمایید");

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: "ثبت محدودیت اقامتگاه",
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var updateResidenceLimitResult =
				await adminController.updateResidenceLimit(
					residenceLimitId,
					residenceId,
					maxTotal,
					startDate,
					endDate,
					createdOn
				);
			if (updateResidenceLimitResult.isSuccess === false)
				throw updateResidenceLimitResult.message;

			await updateResidenceLimitState();

			toast.current.show({
				severity: "success",
				summary: "ثبت محدودیت اقامتگاه",
				detail: localizer("ثبت اطلاعات محدودیت اقامتگاه با موفقیت انجام شد"),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};

	//> updateResidenceLimitState
	const updateResidenceLimitState = async function () {
		try {
			let errors = [];
			//
			setIsSaving(true);
			var updateResidenceLimitStateResult =
				await adminController.updateResidenceLimitStates(residenceLimitStates);
			if (updateResidenceLimitStateResult.isSuccess === false)
				throw updateResidenceLimitStateResult.message;

			toast.current.show({
				severity: "success",
				summary: "ثبت محدودیت اقامتگاه استان ها",
				detail: localizer(
					"ثبت اطلاعات محدودیت اقامتگاه استان ها با موفقیت انجام شد"
				),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};

	//> cancelResidenceLimit
	const cancelResidenceLimit = function () {
		setPageType("list");
	};
	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="residence-limits">
				{pageType === "list" && (
					<>
						<div>
							<Button
								label="ثبت محدودیت اقامتگاه جدید"
								icon={PrimeIcons.PLUS_CIRCLE}
								onClick={() => resetResidenceLimit()}
							/>
						</div>
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>نام اقامتگاه</th>
									<th>حداکثر ظرفیت</th>
									<th>تاریخ شروع</th>
									<th>تاریخ پایان</th>
								</tr>
							</thead>
							<tbody>
								{residenceLimits.map((item) => (
									<tr data-status={item.status}>
										<td>{item.index}</td>
										<td>
											<a
												href="javascript:void(0);"
												onClick={() => editResidenceLimit(item)}
											>
												{item.residenceName}
											</a>
										</td>
										<td>{item.maxTotal}</td>
										<td>{item.startDate}</td>
										<td>{item.endDate}</td>
									</tr>
								))}
							</tbody>
						</table>
						<Paginator
							first={first}
							rows={rows}
							totalRecords={totalRecords}
							onPageChange={onPageChange}
						/>
					</>
				)}

				{pageType === "edit" && (
					<div className="form">
						<div>
							<div>
								<label>حداکثر ظرفیت</label>
								<InputText
									value={maxTotal}
									onChange={(e) => setMaxTotal(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>تاریخ شروع</label>
								<InputText
									value={startDate}
									onChange={(e) => setStartDate(e.target.value)}
								/>
							</div>

							<div>
								<label>تاریخ پایان</label>
								<InputText
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</div>

						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>نام استان</th>
									<th>حداکثر ظرفیت</th>
								</tr>
							</thead>
							<tbody>
								{residenceLimitStates.map((item) => (
									<tr data-status={item.status}>
										<td>{item.index}</td>
										<td>{item.stateName}</td>
										<td>
											<input
												id="maxTotal"
												value={maxTotal}
												onChange={(e) => updateMaxTotal(item.index, e.target.value)}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<div className="buttons">
							<Button
								label="ثبت محدودیت اقامتگاه"
								onClick={() => updateResidenceLimit("Confirm")}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelResidenceLimit()}
								severity="default"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
